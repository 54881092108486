<template>
	<div class="col-lg-6">
		<div class="row">
			<div class="col-xl-12 col-sm-12">
				<div class="card overflow-hidden">
					<div class="card-heading bg-light">
						<h3 class="card-title text-uppercase">Backup Data</h3>
					</div>
					<div class="card-body">
						
					</div>
				</div>
			</div>
			<!-- end col -->
		</div>
		<!--end row -->
	</div>
</template>

<script>
export default {

	name: 'BackupDataComponent',

	data() {
		return {

		};
	},
};
</script>

<style lang="css" scoped>
</style>
